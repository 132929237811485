import './App.scss'
import Layout from './components/Layout'
import { Routes, Route } from 'react-router-dom'
import Home from './components/Home'
import About from './components/About'
import Contact from './components/Contact'
import TimelineChart from './components/VerticalScroller'
import Info from './components/Info'
import 'mapbox-gl/dist/mapbox-gl.css'
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute'
import { TestProvider } from './components/Context'

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />}></Route>
          <Route path="about" element={<About />}></Route>
          <Route element={<ProtectedRoute></ProtectedRoute>}>
            <Route path="contact" element={<Contact />}></Route>
          </Route>

          <Route
            path="my-career"
            element={
              <TestProvider>
                <TimelineChart />
              </TestProvider>
            }
          ></Route>
          <Route path="projects" element={<Info />}></Route>
        </Route>
      </Routes>
    </>
  )
}

export default App
