import './index.scss'
import LogoS from '../../assets/images/headshot.png'
import { Link, NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faDiagramProject,
  faEnvelope,
  faHome,
  faTasks,
  faUser,
} from '@fortawesome/free-solid-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import SchoolIcon from '@mui/icons-material/School'

import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { useState } from 'react'

const DrawerList = () => {
  const [open, setOpen] = useState(false)

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen)
  }

  return (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <List>
        {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {' '}
                <ListItemIcon>
                  <SchoolIcon />
                </ListItemIcon>
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon></ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  )
}
const Sidebar = () => {
  const [open, setOpen] = useState(false)

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen)
  }

  return (
    <>
      <div className="nav-bar">
        <Link className="logo" to="/">
          <img src={LogoS} alt="logo"></img>
        </Link>

        <nav>
          <NavLink exact="true" activeclassname="active" to="/">
            <FontAwesomeIcon icon={faHome} color="#4d4d4e">
              {' '}
            </FontAwesomeIcon>
          </NavLink>
          <NavLink
            exact="true"
            className="about-link"
            activeclassname="active"
            to="/about"
          >
            <FontAwesomeIcon icon={faUser} color="#4d4d4e">
              {' '}
            </FontAwesomeIcon>
          </NavLink>
          <NavLink
            exact="true"
            className="contact-link"
            activeclassname="active"
            to="/contact"
          >
            <FontAwesomeIcon icon={faEnvelope} color="#4d4d4e">
              {' '}
            </FontAwesomeIcon>
          </NavLink>
          <NavLink
            exact="true"
            className="projects"
            activeclassname="active"
            to="/projects"
          >
            <FontAwesomeIcon icon={faDiagramProject} color="#4d4d4e">
              {' '}
            </FontAwesomeIcon>
          </NavLink>
        </nav>
        <ul>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/in/eoin-coulter-3326411b8/"
            />
            <FontAwesomeIcon
              icon={faLinkedin}
              fontSize="2rem"
              color="#4d4d4e"
            />
          </li>
        </ul>
        <Drawer open={open} onClose={toggleDrawer(false)}>
          {DrawerList}
        </Drawer>
      </div>
    </>
  )
}

export default Sidebar
