import React, { useEffect, useState } from 'react'
import Plot from 'react-plotly.js'
import AnimatedLetters from '../AnimatedLetters'
import { DataGrid } from '@mui/x-data-grid'
import './index.scss'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material'
import CustomDialog from '../Dialog'

const text = `I have also gained experience creating custom dashboards using plotly.js. Here 
is live weather data taken to display the temperature in the US. I've also experience using grid libraries like AG-Grid and Material Datagrid for data discovery.`

const apiKey = '8a1c0f41be1ee7adabc49a89be75bc01'

const cities = [
  { state: 'Alabama', city: 'Montgomery', abbreviation: 'AL' },
  { state: 'Alaska', city: 'Juneau', abbreviation: 'AK' },
  { state: 'Arizona', city: 'Phoenix', abbreviation: 'AZ' },
  { state: 'Arkansas', city: 'Little Rock', abbreviation: 'AR' },
  { state: 'California', city: 'Sacramento', abbreviation: 'CA' },
  { state: 'Colorado', city: 'Denver', abbreviation: 'CO' },
  { state: 'Connecticut', city: 'Hartford', abbreviation: 'CT' },
  { state: 'Delaware', city: 'Dover', abbreviation: 'DE' },
  { state: 'Florida', city: 'Tallahassee', abbreviation: 'FL' },
  { state: 'Georgia', city: 'Atlanta', abbreviation: 'GA' },
  { state: 'Hawaii', city: 'Honolulu', abbreviation: 'HI' },
  { state: 'Idaho', city: 'Boise', abbreviation: 'ID' },
  { state: 'Illinois', city: 'Springfield', abbreviation: 'IL' },
  { state: 'Indiana', city: 'Indianapolis', abbreviation: 'IN' },
  { state: 'Iowa', city: 'Des Moines', abbreviation: 'IA' },
  { state: 'Kansas', city: 'Topeka', abbreviation: 'KS' },
  { state: 'Kentucky', city: 'Frankfort', abbreviation: 'KY' },
  { state: 'Louisiana', city: 'Baton Rouge', abbreviation: 'LA' },
  { state: 'Maine', city: 'Augusta', abbreviation: 'ME' },
  { state: 'Maryland', city: 'Annapolis', abbreviation: 'MD' },
  { state: 'Massachusetts', city: 'Boston', abbreviation: 'MA' },
  { state: 'Michigan', city: 'Lansing', abbreviation: 'MI' },
  { state: 'Minnesota', city: 'Saint Paul', abbreviation: 'MN' },
  { state: 'Mississippi', city: 'Jackson', abbreviation: 'MS' },
  { state: 'Missouri', city: 'Jefferson City', abbreviation: 'MO' },
  { state: 'Montana', city: 'Helena', abbreviation: 'MT' },
  { state: 'Nebraska', city: 'Lincoln', abbreviation: 'NE' },
  { state: 'Nevada', city: 'Carson City', abbreviation: 'NV' },
  { state: 'New Hampshire', city: 'Concord', abbreviation: 'NH' },
  { state: 'New Jersey', city: 'Trenton', abbreviation: 'NJ' },
  { state: 'New Mexico', city: 'Santa Fe', abbreviation: 'NM' },
  { state: 'New York', city: 'Albany', abbreviation: 'NY' },
  { state: 'North Carolina', city: 'Raleigh', abbreviation: 'NC' },
  { state: 'North Dakota', city: 'Bismarck', abbreviation: 'ND' },
  { state: 'Ohio', city: 'Columbus', abbreviation: 'OH' },
  { state: 'Oklahoma', city: 'Oklahoma City', abbreviation: 'OK' },
  { state: 'Oregon', city: 'Salem', abbreviation: 'OR' },
  { state: 'Pennsylvania', city: 'Harrisburg', abbreviation: 'PA' },
  { state: 'Rhode Island', city: 'Providence', abbreviation: 'RI' },
  { state: 'South Carolina', city: 'Columbia', abbreviation: 'SC' },
  { state: 'South Dakota', city: 'Pierre', abbreviation: 'SD' },
  { state: 'Tennessee', city: 'Nashville', abbreviation: 'TN' },
  { state: 'Texas', city: 'Austin', abbreviation: 'TX' },
  { state: 'Utah', city: 'Salt Lake City', abbreviation: 'UT' },
  { state: 'Vermont', city: 'Montpelier', abbreviation: 'VT' },
  { state: 'Virginia', city: 'Richmond', abbreviation: 'VA' },
  { state: 'Washington', city: 'Olympia', abbreviation: 'WA' },
  { state: 'West Virginia', city: 'Charleston', abbreviation: 'WV' },
  { state: 'Wisconsin', city: 'Madison', abbreviation: 'WI' },
  { state: 'Wyoming', city: 'Cheyenne', abbreviation: 'WY' },
]

const fetchWeatherData = async (city) => {
  const response = await fetch(
    `https://api.openweathermap.org/data/2.5/weather?q=${city}&appid=${apiKey}&units=imperial`
  )
  const data = await response.json()

  return data
}

const fetchAllWeatherData = async () => {
  const weatherData = await Promise.all(
    cities.map(async ({ state, city, abbreviation }) => {
      const data = await fetchWeatherData(city)
      return {
        state,
        abbreviation,
        temperature: data.main.temp,
      }
    })
  )
  return weatherData
}

const columns = [
  { field: 'abbreviation', headerName: 'State Abbreviation', flex: 1 },
  { field: 'state', headerName: 'State', flex: 1 },
  { field: 'temperature', headerName: 'Temperature (°F)', flex: 1 },
]

const WeatherDashboard = () => {
  const [letterClass, setLetterClass] = useState('text-animate')
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 4000)
  })

  const [data, setData] = useState([])

  useEffect(() => {
    fetchAllWeatherData().then(setData)
  }, [])

  const geoData = data.map((item) => ({
    location: item.abbreviation,
    z: item.temperature,
    text: item.state,
  }))

  const getRowClassName = (params) => {
    if (params.row.temperature >= 90) {
      return 'hot'
    } else if (params.row.temperature >= 75) {
      return 'warm'
    } else if (params.row.temperature >= 60) {
      return 'moderate'
    } else if (params.row.temperature >= 45) {
      return 'cool'
    } else {
      return 'cold'
    }
  }

  return (
    <>
      <div className="container">
        <div className="header">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['P', 'l', 'o', 't', 'l', 'y', '.', ' ', 'j', 's']}
              idx={15}
            />
          </h1>
          {/* <div className="card">
            <ImgMediaCard></ImgMediaCard>
          </div> */}
        </div>
        <div className="contents">
          <div className="chart-container">
            <Plot
              data={[
                {
                  type: 'choropleth',
                  locationmode: 'USA-states',
                  locations: geoData.map((d) => d.location),
                  z: geoData.map((d) => d.z),
                  text: geoData.map((d) => d.text),
                  scrollZoom: true,
                  displayModeBar: false,
                  colorscale: 'Blackbody',
                  reversescale: true,
                  colorbar: {
                    title: 'Temperature (°F)',
                  },
                },
              ]}
              layout={{
                title: 'Current Temperature in Each US State',
                geo: {
                  scope: 'usa',
                },
                autosize: true,

                paper_bgcolor: 'transparent',
                plot_bgcolor: 'transparent',
                font: {
                  color: '#0489ae', // Change color here
                }, // Set the plotting area background color
              }}
              useResizeHandler={true}
              style={{ width: '100%', height: '100%' }}
            />
            <div className="icon">
              <CustomDialog
                title="Ploty.js and Material UI"
                text={text}
              ></CustomDialog>
            </div>
          </div>

          <div className="grid-container">
            <DataGrid
              getRowId={(row) => row.abbreviation}
              rows={data}
              columns={columns}
              pagination
              autoPageSize
              disableSelectionOnClick
              getRowClassName={getRowClassName}
              sx={{
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: 'white', // Dark orange for warmth
                  color: 'black',
                  fontWeight: 'bold',
                },
                '& .MuiDataGrid-footerContainer': {
                  backgroundColor: 'white', // Dark blue for coolness
                  color: 'black',
                  fontWeight: 'bold',
                },
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

function ImgMediaCard() {
  return (
    <Card sx={{ maxWidth: 500 }} className="card-content">
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          D3.js
        </Typography>
        <Typography variant="h5" color="text.secondary">
          Here you can s
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="medium" href="https://d3js.org/">
          Learn More
        </Button>
      </CardActions>
    </Card>
  )
}

export default WeatherDashboard
