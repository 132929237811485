import './index.scss'
import logo from '../../assets/images/logo-e.png'
import { Link } from 'react-router-dom'
import AnimatedLetters from '../AnimatedLetters'
import { useEffect, useState } from 'react'
import Logo from './Logo'
import Loader from 'react-loaders'
import StockChart from '../Charts'
function Image({ isMobile }) {
  if (isMobile) {
    return null
  }
  return <Logo />
}

function ConditionalLink({ isMobile }) {
  if (isMobile) {
    return (
      <Link to="/about" className="flat-button">
        ABOUT ME
      </Link>
    )
  }
  return null
}
const Home = () => {
  const [letterClass, setLetterClass] = useState('text-animate')

  const nameArray = [' ', 'E', 'o', 'i', 'n']
  const jobArray = [
    'S',
    'o',
    'f',
    't',
    'w',
    'a',
    'r',
    'e',
    ' ',
    'E',
    'n',
    'g',
    'i',
    'n',
    'e',
    'e',
    'r',
  ]
  const [width, setWidth] = useState(window.innerWidth)
  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile = width <= 768
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000)

    // Return a cleanup function to clear the timeout when the component unmounts
    return () => clearTimeout(timeoutId)
  }, [])
  return (
    <>
      <div className="container home-page">
        <div className="text-zone">
          <h1>
            <span className={letterClass}>H</span>
            <span className={`${letterClass} _12`}>i,</span>
            <br />
            <span className={`${letterClass} _13`}>I</span>
            <span className={`${letterClass} _14`}>'m</span>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={nameArray}
              idx={15}
            ></AnimatedLetters>{' '}
            <br />
            <AnimatedLetters
              letterClass={letterClass}
              strArray={jobArray}
              idx={16}
            ></AnimatedLetters>{' '}
          </h1>
          <h2>
            Dynamic full stack software engineer with a BSc in Computer Science
            and 3 years experience in both the finance and healthcare
            industries.
          </h2>
          <Link to="/contact" className="flat-button">
            CONTACT ME
          </Link>
          <ConditionalLink isMobile={isMobile} />
          <a
            href="/EoinCoulterResume.pdf"
            target="_blank"
            className="flat-button download-button"
          >
            DOWNLOAD RESUME
          </a>
        </div>
      </div>
      <Image isMobile={isMobile}></Image>
      <Loader type="pacman" />
    </>
  )
}

export default Home
