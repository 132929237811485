import { useEffect, useRef, useState } from 'react'
import LogoS from '../../../assets/images/se.gif'
import './index.scss'
import Fade from '@mui/material/Fade'

const Logo = () => {
  const solidLogoRef = useRef()
  const [showImage, setShowImage] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowImage(true)
    }, 3000) // 3000 milliseconds = 3 seconds

    return () => clearTimeout(timer)
  }, []) // Empty

  return (
    <div className="logo-container">
      <Fade in={showImage} timeout={3000}>
        {
          <img
            className={'solid-logo'}
            ref={solidLogoRef}
            src={LogoS}
            alt="JavaScript,  Developer"
          />
        }
      </Fade>
    </div>
  )
}

export default Logo
