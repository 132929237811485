import { createContext, useState } from 'react'

const TestContext = createContext()

export const TestProvider = ({ children }) => {
  const [test, setTest] = useState('testProvider')

  return (
    <TestContext.Provider value={{ test, setTest }}>
      {children}
    </TestContext.Provider>
  )
}
export default TestContext
