import { useState, useEffect } from 'react'
import axios from 'axios'

const useGetAllData = (urls) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const requests = urls.map((url) => axios.get(url))
        const responses = await axios.all(requests)
        const data = responses.map((response) => response.data)
        setData(data)
      } catch (err) {
        setError(err.message)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [urls])

  return { data, loading, error }
}

export default useGetAllData
