import axios from 'axios'
import { useEffect, useState } from 'react'
import useGetData from '../Hooks/get'
import './index.scss'
import {
  Area,
  AreaChart,
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  Legend,
  Line,
  PolarAngleAxis,
  PolarRadiusAxis,
  RadialBar,
  RadialBarChart,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import useGetAllData from '../Hooks/getMultipleRequests'
import CustomDialog from '../Dialog'
import AnimatedLetters from '../AnimatedLetters'
import { Loader } from '@chatscope/chat-ui-kit-react'

const Recharts = () => {
  const text = `This dashboard provides an in-depth overview of data asynchronously requested from the Star Wars API, focusing on planets and vehicles.\n
It features several interactive charts created using Recharts.
At the top, a Radial Bar Chart visualizes planet data. It displays each planet's diameter.\n
Next to it, a Stacked Area Chart shows the orbital period and diameter of each planet, using different colors for each metric and providing tooltips for detailed information.\n
Below, a Composed Chart presents vehicle data. It displays cargo capacity, cost in credits, max atmospheric speed, and crew size using different chart types like area, bar, line, and scatter plot, with logarithmic scaling on the Y-axis for better visualization of varied data ranges`
  const { data, loading, error } = useGetAllData([
    'https://swapi.dev/api/planets',
    'https://swapi.dev/api/vehicles',
  ])
  const [letterClass, setLetterClass] = useState('text-animate')

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 4000)
    return () => clearTimeout(timeoutId)
  }, [])

  if (loading) return <Loader type="pacman" />

  if (error) return <p>Error: {error}</p>
  if (!data) return <p>No data available</p>
  const style = {
    bottom: '10%',
    right: 0,
    transform: 'translate(0, -50%)',
    lineHeight: '24px',
  }

  const colors = [
    '#1f77b4', // Blue
    '#ff7f0e', // Orange
    '#2ca02c', // Green
    '#d62728', // Red
    '#9467bd', // Purple
    '#8c564b', // Brown
    '#e377c2', // Pink
    '#7f7f7f', // Gray
    '#bcbd22', // Olive
    '#17becf', // Teal
  ]
  const planets = data[0].results.filter((planet) => planet.name !== 'Bespin')
  const vehicles = data[1].results.filter(
    (ship) => ship.name !== 'AT-AT' && ship.name !== 'TIE bomber'
  )
  return (
    <div className="page-container">
      {!planets ? (
        <p>No Data</p>
      ) : (
        <>
          <div className="header">
            <h1>
              <AnimatedLetters
                letterClass={letterClass}
                strArray={['R', 'e', 'c', 'h', 'a', 'r', 't', 's']}
                idx={40}
              />
            </h1>
          </div>
          <div className="top-row">
            <div className="radial">
              <ResponsiveContainer width="100%" height="100%">
                <RadialBarChart
                  innerRadius="20%"
                  outerRadius="80%"
                  barSize={100}
                  data={planets}
                  startAngle={180}
                  endAngle={0}
                >
                  <Tooltip
                    content={({ payload }) => {
                      if (payload && payload.length) {
                        return (
                          <div
                            style={{
                              backgroundColor: '#fff',
                              border: '1px solid #ccc',
                              padding: '5px',
                              borderRadius: '3px',
                            }}
                          >
                            <p>{payload[0].payload.name}</p>
                            <p>Diameter {payload[0].value}km</p>
                          </div>
                        )
                      }
                      return null
                    }}
                  ></Tooltip>

                  <PolarAngleAxis type="number" domain={[0, 20000]} />
                  <RadialBar
                    minAngle={15}
                    label={{ position: 'insideStart', fill: '#fff' }}
                    background
                    clockWise
                    strokeWidth={10} // Adjust the stroke width here
                    dataKey="diameter"
                  >
                    {planets.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={colors[index]} />
                    ))}
                  </RadialBar>
                  <Legend
                    iconSize={10}
                    layout="horizontal"
                    verticalAlign="bottom"
                    wrapperStyle={style}
                  />
                </RadialBarChart>
              </ResponsiveContainer>
            </div>
            <div className="stacked">
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  width={500}
                  height={400}
                  data={planets}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />

                  <Area
                    type="monotone"
                    dataKey="orbital_period"
                    stackId="1"
                    stroke={colors[1]}
                    fill={colors[1]}
                  />
                  <Area
                    type="monotone"
                    dataKey="diameter"
                    stackId="1"
                    stroke={colors[2]}
                    fill={colors[2]}
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
            <div className="icon">
              <CustomDialog
                title="Recharts and axios.js"
                text={text}
              ></CustomDialog>
            </div>
          </div>
          <div className="bottom-row">
            <ResponsiveContainer width="100%" height="80%">
              <ComposedChart
                width={500}
                height={300}
                data={vehicles}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20,
                }}
              >
                <CartesianGrid stroke="#f5f5f5" />
                <XAxis dataKey="name" />
                <YAxis scale="log" domain={['auto', 'auto']} />
                <Tooltip />
                <Legend />
                <Area
                  type="monotone"
                  dataKey="cargo_capacity"
                  fill="#8884d8"
                  stroke="#8884d8"
                />
                <Bar dataKey="cost_in_credits" barSize={20} fill="#413ea0" />
                <Line
                  type="monotone"
                  dataKey="max_atmospheric_speed"
                  stroke="#ff7300"
                />
                <Scatter dataKey="crew" fill="red" />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </>
      )}
    </div>
  )
}

export default Recharts
