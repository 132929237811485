import React, { useEffect, useState, useRef } from 'react'
import * as d3 from 'd3'
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid'
import './index.scss'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
  Typography,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import InfoIcon from '@mui/icons-material/Info'
import d3Image from '../../assets/images/d3js.png'
import CustomDialog from '../Dialog'

const text = `Throughout my career I've developed skills in visualization for
large complex datasets. Here is a dynamic, interactive chart
displaying USD exchange rates with various currencies. This data
is is obatined using axios.js to consume an open source API.`

const CurrencyChart = () => {
  const [letterClass, setLetterClass] = useState('text-animate')
  const [data, setData] = useState([])
  const chartRef = useRef()
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 4000)
  })
  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseEUR = await fetch(
          'https://api.frankfurter.app/2023-01-01..2023-12-31?from=USD&to=EUR'
        )
        const resultEUR = await responseEUR.json()
        const ratesEUR = Object.keys(resultEUR.rates).map((date) => ({
          id: date,
          date: new Date(date),
          rateEUR: resultEUR.rates[date].EUR,
        }))

        const responseGBP = await fetch(
          'https://api.frankfurter.app/2023-01-01..2023-12-31?from=USD&to=GBP'
        )
        const resultGBP = await responseGBP.json()
        const ratesGBP = Object.keys(resultGBP.rates).map((date) => ({
          id: date,
          date: new Date(date),
          rateGBP: resultGBP.rates[date].GBP,
        }))

        const responseCHF = await fetch(
          'https://api.frankfurter.app/2023-01-01..2023-12-31?from=USD&to=CHF'
        )
        const resultCHF = await responseCHF.json()
        const ratesCHF = Object.keys(resultCHF.rates).map((date) => ({
          id: date,
          date: new Date(date),
          rateCHF: resultCHF.rates[date].CHF,
        }))
        const responseSGD = await fetch(
          'https://api.frankfurter.app/2023-01-01..2023-12-31?from=USD&to=SGD'
        )
        const resultSGD = await responseSGD.json()
        const ratesSGD = Object.keys(resultSGD.rates).map((date) => ({
          id: date,
          date: new Date(date),
          rateSGD: resultSGD.rates[date].SGD,
        }))

        const responseAUD = await fetch(
          'https://api.frankfurter.app/2023-01-01..2023-12-31?from=USD&to=AUD'
        )
        const resultAUD = await responseAUD.json()
        const ratesAUD = Object.keys(resultAUD.rates).map((date) => ({
          id: date,
          date: new Date(date),
          rateAUD: resultAUD.rates[date].AUD,
        }))

        // Combine all currency data into a single array
        const combinedData = ratesEUR.map((rate, index) => ({
          ...rate,
          rateGBP: ratesGBP[index] ? ratesGBP[index].rateGBP : null,
          rateCHF: ratesCHF[index] ? ratesCHF[index].rateCHF : null,
          rateAUD: ratesAUD[index] ? ratesAUD[index].rateAUD : null,
          rateSGD: ratesSGD[index] ? ratesSGD[index].rateSGD : null,
        }))

        setData(combinedData)
      } catch (error) {
        console.error('Error fetching currency data:', error)
      }
    }

    fetchData()
  }, [])

  const renderChart = () => {
    if (!data.length) return

    const svg = d3.select(chartRef.current)
    svg.selectAll('*').remove() // Clear previous contents

    const margin = { top: 50, right: 0, bottom: 50, left: 40 }
    const width = chartRef.current.clientWidth - margin.left - margin.right
    const height = chartRef.current.clientHeight - margin.top - margin.bottom

    const g = svg
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`)

    const x = d3
      .scaleTime()
      .domain(d3.extent(data, (d) => d.date))
      .range([0, width])
    const y = d3
      .scaleLinear()
      .domain([
        d3.min(data, (d) => Math.min(d.rateEUR, d.rateGBP, d.rateAUD)),
        d3.max(data, (d) => Math.max(d.rateEUR, d.rateGBP, d.rateAUD)),
      ])
      .range([height, 0])

    g.append('g')
      .attr('transform', `translate(0,${height})`)
      .call(d3.axisBottom(x))
      .selectAll('path,line')
      .attr('stroke', '#0489ae')

    g.append('g')
      .call(d3.axisLeft(y))
      .selectAll('path,line')
      .attr('stroke', '#0489ae')

    g.selectAll('text').attr('fill', '#0489ae')

    svg
      .append('text')
      .attr('class', 'x-axis-label')
      .attr('text-anchor', 'middle')
      .attr('x', margin.left + width / 2)
      .attr('y', height + margin.top + 40)
      .attr('fill', '#0489ae')
      .text('Date')
      .style('font-weight', 'bold')

    // Y Axis Label
    svg
      .append('text')
      .attr('class', 'y-axis-label')
      .attr('text-anchor', 'middle')
      .attr(
        'transform',
        `translate(${margin.left - 30},${margin.top + height / 2})rotate(-90)`
      )
      .attr('fill', '#0489ae')
      .text('Exchange Rate')
      .style('font-weight', 'bold')

    // Chart Title
    svg
      .append('text')
      .attr('class', 'chart-title')
      .attr('text-anchor', 'middle')
      .attr('x', margin.left + width / 2)
      .attr('y', margin.top - 20)
      .attr('fill', '#0489ae')
      .attr('font-size', '20px')
      .text('USD Exchange Rates Over Time')

    const drawLine = (data, color, yValue) => {
      const path = g
        .append('path')
        .datum(data)
        .attr('fill', 'none')
        .attr('stroke', color)
        .attr('stroke-width', 1.5)
        .attr(
          'd',
          d3
            .line()
            .x((d) => x(d.date))
            .y((d) => y(d[yValue]))
        )

      const totalLength = path.node().getTotalLength()

      path
        .attr('stroke-dasharray', `${totalLength} ${totalLength}`)
        .attr('stroke-dashoffset', totalLength)
        .transition()
        .duration(2000)
        .ease(d3.easeLinear)
        .attr('stroke-dashoffset', 0)
    }

    // Draw lines with animations
    drawLine(data, 'steelblue', 'rateEUR')
    drawLine(data, 'orange', 'rateGBP')
    drawLine(data, 'green', 'rateCHF')
    drawLine(data, 'red', 'rateAUD')
    drawLine(data, 'purple', 'rateSGD')

    // Tooltip
    const tooltip = d3.select('.tooltip')

    const mouseover = () => tooltip.style('display', 'block')
    const mousemove = (event, d) => {
      const [x, y] = d3.pointer(event)
      tooltip
        .html(
          `Date: ${d3.timeFormat('%Y-%m-%d')(d.date)}<br>EUR: ${
            d.rateEUR
          }<br>GBP: ${d.rateGBP}<br>CHF: ${d.rateCHF}<br>AUD: ${d.rateAUD}`
        )
        .style('left', `${x + margin.left + 10}px`)
        .style('top', `${y + margin.top + 10}px`)
    }
    const mouseout = () => tooltip.style('display', 'none')

    // Circles for tooltips
    const currencies = [
      { key: 'rateEUR', color: 'steelblue' },
      { key: 'rateGBP', color: 'orange' },
      { key: 'rateAUD', color: 'red' },
      { key: 'rateCHF', color: 'green' },
      { key: 'rateSGD', color: 'purple' },
    ]

    const delay = 2000

    currencies.forEach((currency, index) => {
      g.selectAll(`circle.${currency.key}`)
        .data(data)
        .enter()
        .append('circle')
        .attr('class', currency.key)
        .attr('cx', (d) => x(d.date))
        .attr('cy', (d) => y(d[currency.key]))
        .attr('r', 0)
        .attr('fill', currency.color)
        .transition()
        .delay(delay)
        .duration(500)
        .attr('r', 2)

      g.selectAll(`circle.${currency.key}`)
        .on('mouseover', mouseover)
        .on('mousemove', mousemove)
        .on('mouseout', mouseout)
    })

    // Legend
    const legend = svg
      .append('g')
      .attr('class', 'legend')
      .attr('transform', `translate(${margin.left + 20}, ${margin.top - 40})`)

    legend
      .append('rect')
      .attr('x', 0)
      .attr('y', -5)
      .attr('width', 120)
      .attr('height', 110)
      .attr('fill', 'rgba(0, 0, 0, 0.8)')
      .attr('rx', 5)
      .attr('ry', 5)

    legend
      .append('circle')
      .attr('cx', 10)
      .attr('cy', 10)
      .attr('r', 5)
      .attr('fill', 'steelblue')

    legend
      .append('text')
      .attr('x', 20)
      .attr('y', 14)
      .attr('fill', 'white')
      .text('USD to EUR')

    legend
      .append('circle')
      .attr('cx', 10)
      .attr('cy', 30)
      .attr('r', 5)
      .attr('fill', 'orange')

    legend
      .append('text')
      .attr('x', 20)
      .attr('y', 34)
      .attr('fill', 'white')
      .text('USD to GBP')

    legend
      .append('circle')
      .attr('cx', 10)
      .attr('cy', 50)
      .attr('r', 5)
      .attr('fill', 'red')

    legend
      .append('text')
      .attr('x', 20)
      .attr('y', 54)
      .attr('fill', 'white')
      .text('USD to AUD')

    legend
      .append('circle')
      .attr('cx', 10)
      .attr('cy', 70)
      .attr('r', 5)
      .attr('fill', 'green')

    legend
      .append('text')
      .attr('x', 20)
      .attr('y', 74)
      .attr('fill', 'white')
      .text('USD to CHF')

    legend
      .append('circle')
      .attr('cx', 10)
      .attr('cy', 90)
      .attr('r', 5)
      .attr('fill', 'purple')

    legend
      .append('text')
      .attr('x', 20)
      .attr('y', 94)
      .attr('fill', 'white')
      .text('USD to SGD')
  }

  useEffect(() => {
    renderChart()

    const handleFullscreenChange = () => {
      renderChart()
    }

    const handleResize = () => {
      renderChart()
    }

    window.addEventListener('resize', handleResize)
    document.addEventListener('fullscreenchange', handleFullscreenChange)

    return () => {
      window.removeEventListener('resize', handleResize)
      document.removeEventListener('fullscreenchange', handleFullscreenChange)
    }
  }, [data])

  return (
    <div className="container">
      <div className="header">
        <h1>
          <AnimatedLetters
            letterClass={letterClass}
            strArray={['D', '3', '.', ' ', 'j', 's']}
            idx={15}
          />
        </h1>
      </div>
      <div className="contents">
        <div className="tooltip"></div>

        <div className="chart-container">
          <svg ref={chartRef} style={{ width: '100%', height: '100%' }} />
        </div>
        <div className="icon-container">
          <CustomDialog title="D3.js" text={text}></CustomDialog>
        </div>
      </div>
    </div>
  )
}

export default CurrencyChart
