import React, { useState } from 'react'
import './index.scss'
import { Tabs, Tab, Box } from '@mui/material'
import PhoneIcon from '@mui/icons-material/Phone'
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed'
import FavoriteIcon from '@mui/icons-material/Favorite'
import PersonPinIcon from '@mui/icons-material/PersonPin'
import About from '../About'
import StockChart from '../Charts'
import CurrencyChart from '../Charts'
import Loader from 'react-loaders'
import Chat from '../Chatbot'
import WeatherDashboard from '../Plotly'
import WalkCalculator from '../Maps'
import Recharts from '../Recharts'

const Info = () => {
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <>
      <div className="page-container">
        <swiper-container
          space-between="10"
          slides-per-view="1"
          navigation="true"
        >
          <swiper-slide>
            <CurrencyChart />
          </swiper-slide>
          <swiper-slide>
            <WeatherDashboard></WeatherDashboard>
          </swiper-slide>
          {/* <swiper-slide>
            <WalkCalculator />
          </swiper-slide> */}
          <swiper-slide>
            <Recharts />
          </swiper-slide>
        </swiper-container>

        <Loader type="pacman" />
      </div>
    </>
  )
}

export default Info
